import { render, staticRenderFns } from "./BonusItemsTable.vue?vue&type=template&id=7f559b21&scoped=true"
import script from "./BonusItemsTable.vue?vue&type=script&lang=js"
export * from "./BonusItemsTable.vue?vue&type=script&lang=js"
import style0 from "./BonusItemsTable.vue?vue&type=style&index=0&id=7f559b21&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f559b21",
  null
  
)

export default component.exports